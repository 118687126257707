import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const MicroNeedling = ({ data, location, ...props }) => {
	return (
		<Layout location={location} title="Micro-Needling" noSlider {...props}>
			<p>
				Dieses Verfahren wurde über nahezu 100 Jahre kontinuierlich
				entwickelt und liefert für viele Indikationen wahrhaft
				ausgezeichnete Ergebnisse.
			</p>
			<p>
				Mit einem Nadelroller oder einem elektrischen Nadelgerät werden
				mikrofeine Nadel in engem Abstand bis zu 3 mm in die Haut
				lanciert. Je nach Eindringtiefe unterscheidet man das
				kosmetische und das medizinische Needling, wobei letzteres mit
				1-3 mm Tiefe nur ausgebildeten Ärzten vorbehalten bleibt. Die
				Wirkung an der Haut korreliert mit der Eindringtiefe der Nadeln.
			</p>
			<p>
				Mit dem Eindringen der Nadeln in die Haut wird das
				Unterhautgewebe zur Produktion von Kollagen angeregt, man
				spricht auch von percutaner Kollageninduktion.
			</p>
			<p>Damit nimmt die Hautdicke zu.</p>
			<p>
				Ebenfalls konnte gezeigt werden, dass die Hautelastizität, die
				Feuchtigkeit und die Hautdurchblutung verbessert werden.
			</p>
			<p>
				Für ein wirklich gutes Ergebnis sind 3-5 Behandlungen im Abstand
				von 4-6 Wochen zu kalkulieren. Die Ausfallzeit liegt bei einem
				Wochenende.
			</p>
			<p>
				Indikationen für das Needling:
				<ul>
					<li>Aknenarben</li>
					<li>Narben</li>
					<li>Dehnungsstreifen der Haut</li>
					<li>Verbesserung der Hautstruktur</li>
					<li>Glanz, Frische, bessere Spannkraft der Haut</li>
					<li>Reduktion von Fältchen</li>
				</ul>
			</p>

			<Questions title="Micro-Needling" prefix="um" />
		</Layout>
	);
};

export default MicroNeedling;
